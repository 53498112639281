/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
html,
body,
#root {
  height: 100%;
}
.colorWeak {
  -webkit-filter: invert(80%);
          filter: invert(80%);
}
.ant-layout {
  min-height: 100vh;
}
.ant-pro-basicLayout {
  height: 100%;
}
canvas {
  display: block;
}
body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ul,
ol {
  list-style: none;
}
@media (max-width: 480px) {
  .ant-table {
    width: 100%;
    overflow-x: auto;
  }
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > th,
  .ant-table-thead > tr > td,
  .ant-table-tbody > tr > td {
    white-space: pre;
  }
  .ant-table-thead > tr > th > span,
  .ant-table-tbody > tr > th > span,
  .ant-table-thead > tr > td > span,
  .ant-table-tbody > tr > td > span {
    display: block;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body .ant-design-pro > .ant-layout {
    min-height: 100vh;
  }
}
.ant-menu-horizontal > .ant-menu-item a:hover {
  color: white !important;
}
.operationPage .ant-pro-page-container {
  height: 100%;
}
.operationPage .ant-pro-page-container .ant-pro-grid-content {
  height: calc(100% - 113px);
}
.operationPage .ant-pro-page-container .ant-pro-grid-content .ant-pro-grid-content-children {
  height: 100%;
}
.operationPage .ant-pro-page-container .ant-pro-grid-content .ant-pro-grid-content-children > div {
  height: 100%;
}
.operationPage .ant-pro-page-container .ant-pro-grid-content .ant-pro-grid-content-children > div .ant-pro-page-container-children-content {
  height: 100%;
  margin: 0 !important;
}
.w-split-horizontal > .w-split-line-bar div:after {
  width: 3px !important;
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.ant-drawer .ant-picker-clear,
.ant-drawer .ant-slider-handle,
.ant-drawer .ant-anchor-wrapper,
.ant-drawer .ant-collapse-content,
.ant-drawer .ant-timeline-item-head,
.ant-drawer .ant-card {
  background-color: #141414 !important;
}
.deviceStatus > .ant-badge-status-dot {
  width: 10px;
  height: 10px;
}
.deviceStatus > .ant-badge-status-processing {
  background-color: #a0d911;
}
.deviceStatus > .ant-badge-status-default {
  background-color: rgba(255, 255, 255, 0.2);
}
.ant-card-extra {
  padding: inherit !important;
}
